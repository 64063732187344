<template>
  <div>
    <Button
      v-if="order.has_returnable_items"
      :size="ButtonSizes.SMALL"
      :count="rmaContainer.getDraftLineQuantity({ invoice_number: order.invoice_number })"
      @click.native.stop="openDialog"
    >
      {{ $t('order.controls.return_items') }}
    </Button>
    <Modal ref="modal" title="Returnering" :size="ModalSizes.LARGE" @close="onModalClose">
      <template #default>
        <RmaLinePicker
          :lookup="{ query: order.invoice_number.toString(), type: 'invoiceNumber' }"
          addFoundLines
        />
      </template>
      <template #controls="{ close }">
        <Button type="light" @click="close">Vælg flere</Button>
        <Button type="cta" @click="submitDraftLines">Opret returnering</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import { Modal, Sizes as ModalSizes } from '@components/Modal';
import RmaLinePicker from '@scenes/SelfserviceScene/components/RmaLinePicker';

export default {
  name: 'RmaButton',

  components: {
    Button,
    Modal,
    RmaLinePicker,
  },

  enums: {
    ButtonSizes,
    ModalSizes,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  methods: {
    openDialog() {
      this.$refs.modal.open();
    },
    onModalClose(cancel) {
      // TODO: The whole thing needs a good refactor. Modals inside a table??
      // Could we make a RmaModalsView extending the RmaContainer with modals that support the actions provided.
      // Without the delay the close "click" event would trigger a row click, redirecting the user to the specific order.
      if (cancel) {
        this.rmaContainer.disableSaveDrafts();
      } else {
        this.rmaContainer.enableSaveDrafts();
      }
    },
    submitDraftLines() {
      this.rmaContainer.createRma().then(rmaId => {
        this.rmaContainer
          .submitDraftLines(rmaId, 'ReturnOfGoods')
          .then(rma => {
            this.$router.push({ name: '/selfservice/returns/:id', params: { id: rmaId } });
          })
          .catch(() => {});
      });
    },
  },

  inject: ['rmaContainer'],
};
</script>

<style lang="scss" scoped>
.Controls {
  display: flex;
  justify-content: flex-end;
  align-items: bottom;
}
</style>
