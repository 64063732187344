import { getOrders } from '@services/thansen/orders';
import { filterByQuery } from '@helpers/array';
import { isEmpty } from '@helpers/object';
import Progress from '@types/Progress';
import dayjs from 'dayjs';

export const Filters = {
  DATES: 'DATES',
  REFINEMENTS: 'REFINEMENTS',
  LIMIT: 'LIMIT',
};

export default {
  name: 'OrdersContainer',

  props: {
    itemsPerPage: {
      type: Number,
      default: 30,
    },
    searchableAttributes: {
      type: Array,
      default() {
        return [];
      },
    },
    routing: {
      type: Boolean,
      default: false,
    },
    initialStartDate: {
      type: Date,
      default: undefined,
    },
    initialEndDate: {
      type: Date,
      default: undefined,
    },
  },

  data() {
    return {
      orders: [],
      status: '',
      page: 1,
      searchQuery: '',
      excludeDatesInRoute: true,
      startDate: this.initialStartDate,
      endDate: this.initialEndDate,
      refinements: {},
      limit: null,
    };
  },

  computed: {
    searchedOrders() {
      return filterByQuery(this.orders, this.searchQuery, this.searchableAttributes);
    },
    pageOrders() {
      return this.searchedOrders.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage,
      );
    },
    totalPages() {
      return Math.ceil(this.searchedOrders.length / this.itemsPerPage);
    },
    searchedTotal() {
      return this.searchedOrders.length;
    },
    total() {
      return this.orders.length;
    },
  },

  created() {
    if (this.routing) {
      this.readRoute();
      const filters = this.extractFiltersFromRoute();
      this.fetchOrders(filters.length ? filters : [Filters.DATES]);
    }
  },

  methods: {
    readRoute() {
      if (this.$route.query.page) {
        this.page = parseInt(this.$route.query.page);
      }
      if (this.$route.query.from && this.$route.query.to) {
        const range = {
          start: new Date(this.$route.query.from),
          end: new Date(this.$route.query.to),
        };
        this.setDates(range);
      }
      Object.keys(this.$route.query).forEach(queryKey => {
        const refinementKey = queryKey.match(/refinement\[(.*?)\]/)?.[1];
        if (refinementKey) this.refinements[refinementKey] = this.$route.query[queryKey];
      });
      if (this.$route.query.limit) {
        this.limit = parseInt(this.$route.query.limit);
      }
    },
    extractFiltersFromRoute() {
      const filters = [];
      const query = this.$route.query;
      if (query.from && query.to) filters.push(Filters.DATES);
      if (Object.keys(query).some(x => x.includes('refinement'))) filters.push(Filters.REFINEMENTS);
      if (query.limit) filters.push(Filters.LIMIT);
      return filters;
    },
    setSearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    setPage(page) {
      this.page = page;
      this.updateRoutePage();
    },
    setDates(range) {
      this.excludeDatesInRoute = false;
      this.startDate = range.start;
      this.endDate = range.end;
    },
    setRefinements(refinements) {
      this.refinements = refinements;
    },
    clearRefinements() {
      this.refinements = {};
    },
    setLimit(limit) {
      this.limit = limit;
    },
    search(filters) {
      this.page = 1;
      if (this.routing) this.updateRoute(filters);
      this.fetchOrders(filters);
    },
    updateRoute(filters) {
      const query = {
        ...this.getPageQuery(),
        ...(filters.includes(Filters.DATES) && this.getDatesQuery()),
        ...(filters.includes(Filters.REFINEMENTS) && this.getRefinementsQuery()),
        ...(filters.includes(Filters.LIMIT) && this.getLimitQuery()),
      };
      this.setRouteQuery(query);
    },
    updateRoutePage() {
      const { page, ...rest } = this.$route.query;
      this.setRouteQuery({ ...rest, ...this.getPageQuery() });
    },
    getPageQuery() {
      return this.page > 1 ? { page: this.page } : {};
    },
    getDatesQuery() {
      const query = {};
      if (this.excludeDatesInRoute) return query;
      if (this.startDate) query.from = dayjs(this.startDate).format('YYYY-MM-DD');
      if (this.endDate) query.to = dayjs(this.endDate).format('YYYY-MM-DD');
      return query;
    },
    getRefinementsQuery() {
      const query = {};
      Object.keys(this.refinements).forEach(key => {
        if (this.refinements[key]) query[`refinement[${key}]`] = this.refinements[key];
      });
      return query;
    },
    getLimitQuery() {
      return this.limit ? { limit: this.limit } : {};
    },
    setRouteQuery(query) {
      if (this.isSameObject(this.$route.query, query)) return;
      this.$router.replace({ name: this.$route.name, query });
    },
    isSameObject(a, b) {
      if (a === b) return true;
      if (a === null || b === null) return false;
      if (Object.keys(a).length !== Object.keys(b).length) return false;
      return Object.keys(a).every(key => b.hasOwnProperty(key) && a[key] === b[key]);
    },
    fetchOrders(filters) {
      const params = this.getParams(filters);
      this.status = Progress.WORKING;
      getOrders(params)
        .then(data => {
          this.orders = data;
          this.status = Progress.COMPLETE;
        })
        .catch(error => {
          this.status = Progress.ERROR;
        });
    },
    getParams(filters) {
      const params = {};
      if (filters.includes(Filters.DATES) && this.startDate && this.endDate) {
        params.start_date = dayjs(this.startDate).format('DD-MM-YYYY');
        params.end_date = dayjs(this.endDate).format('DD-MM-YYYY');
      }
      if (filters.includes(Filters.REFINEMENTS) && !isEmpty(this.refinements)) {
        params.refinements = this.getParsedRefinements();
      }
      if (filters.includes(Filters.LIMIT) && this.limit) {
        params.limit = this.limit;
      }
      return params;
    },
    getParsedRefinements() {
      return Object.entries(this.refinements)
        .filter(entry => entry[1])
        .map(entry => `${entry[0]}:${entry[1]}`);
    },
  },

  render() {
    return this.$scopedSlots.default({
      status: this.status,
      total: this.total,
    });
  },

  provide() {
    return {
      ordersContainer: this,
    };
  },
};
