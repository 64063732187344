<template>
  <DetailsCard :details="details" class="OrderCard">
    <template #controls v-if="$isEnabled(Features.ACCOUNT_RETURNS) && order.has_returnable_items">
      <RmaButton :order="order" />
    </template>
  </DetailsCard>
</template>

<script>
import DetailsCard, { Formats } from '@scenes/SelfserviceScene/components/DetailsCard';
import OrderStatusLabel from '@scenes/SelfserviceScene/components/OrderStatusLabel';
import RmaButton from './components/RmaButton';
import Features from '@types/Features';

export default {
  name: 'OrderCard',

  components: {
    DetailsCard,
    RmaButton,
  },

  enums: {
    Features,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    details() {
      return [
        {
          header: this.$t('order.order_number'),
          label: this.order.order_number,
          linkTo: { name: '/selfservice/orders/:id', params: { id: this.order.token } },
          format: Formats.LINK,
        },
        {
          header: this.$t('order.created'),
          label: this.order.created,
          format: Formats.DATE,
          dateFormat: 'lll',
        },
        this.$isEnabled(Features.ACCOUNT_DELIVERY) && {
          header: this.$t('special_delivery.departure'),
          label: this.order.latest_departure,
          format: Formats.DATE,
          dateFormat: 'lll',
        },
        {
          header: this.$t('order.status'),
          template: OrderStatusLabel,
          templateProps: { solid: true, status: this.order.status },
        },
        { header: this.$t('order.invoice_number'), label: this.order.invoice_number },
        { header: this.$t('price.total'), label: this.order.amount, format: Formats.PRICE },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderCard {
  .OrderCard__kicker {
    color: #878787;
  }

  .OrderCard__title {
    font-weight: bold;
    font-size: 18px;
    color: $color-prim-blue;
    text-decoration: underline;
  }

  .OrderCard__link {
    color: $color-prim-blue;
    font-weight: bold;
    padding: 10px 0px;
    font-size: 18px;
  }
}
</style>
